export default [
  {
    role: 'admin',
    capabilities: [
      'view-all',
      'end-shift',
      'create-dynamicAdjustments-rework',
      'product-write-access',
      'edit-line-settings',
      'update-downtimeLog',
      'audit-trail-access',
      'create-dynamicAdjustments-add-rejections',
    ],
    readOnly: true,
  },
  {
    role: 'operator',
    capabilities: [
      'view-home',
      'view-observer',
      'create-dynamicAdjustments-rework',
      'create-dynamicAdjustments-add-rejections',
    ],
    readOnly: true,
  },
  {
    role: 'lineLeader',
    capabilities: [
      'view-all',
      'end-shift',
      'create-dynamicAdjustments-rework',
      'update-downtimeLog',
      'create-dynamicAdjustments-add-rejections',
    ],
    readOnly: true,
  },
  {
    role: 'hallLeader',
    capabilities: [
      'view-all',
      'end-shift',
      'create-dynamicAdjustments-rework',
      'create-dynamicAdjustments-add-rejections',
    ],
    readOnly: true,
  },
  {
    role: 'plantLeader',
    capabilities: [
      'view-all',
      'end-shift',
      'create-dynamicAdjustments-rework',
      'create-dynamicAdjustments-add-rejections',
    ],
    readOnly: true,
  },
];
