import { stringify } from 'qs';
import request from '@/utils/request';
import download from 'downloadjs';
import config from '@/../config';
import moment from 'moment-timezone';
import AuthService from '../utils/AuthProvider'


const apis = config.generalFunctionApis
/*const apis = {
  'accountCreationAlert':'https://4rzskbcp6ppbdh74zzkw6pfymq0vzphe.lambda-url.us-east-2.on.aws/',
  'emailOnLineSettingsChange':'https://wd66kw5yzs6zoynrun77yvtene0iqilv.lambda-url.us-east-2.on.aws/',
  'emailOnRejection':'https://sznyyq64yn6lazg55yinlcxit40qdapw.lambda-url.us-east-2.on.aws/',
  'emailOnRework':'https://677el22x47pngu3rhyygsf2lm40amxjt.lambda-url.us-east-2.on.aws/',
  'emailOnStopShift':'https://uiw2zr5dong7uvzg7lbvez46jm0awirw.lambda-url.us-east-2.on.aws/',
  'exportReport':'https://uygmdjgriixtlyimmpavraqh2i0przfz.lambda-url.us-east-2.on.aws/',
  'generateManualStatsForShift':'https://tk67og4dulhyojovezi5xlixfe0sfqwu.lambda-url.us-east-2.on.aws/',
  

}*/
export const exportReport = async params => {
  try {
    var formBody = [];
    for (var property in params) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let token = await AuthService.getToken()
    let url = apis['exportReport']
    let res = await fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(params)
    })

    const response = await res.text()
    if(!params.rangeType)
      params.rangeType = 'All_Plant'
    download(response, `${params.rangeType}_${params.type}_Report.csv`, 'text/csv');
    return true;
  } catch (error) {
    console.log('error', error);
    return false;
  }
};


/**
 * send email when product is changed in a shift
 * @param {string} productionLineId 
 */


export const emailOnRejectionOrRework = async ({ productionLine, currentShift, value, api, machine }) => {
  if (currentShift != null) {
    let generateAlert = true
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    // if (productionLine.shifts.length > 0)
    // {
    let date = moment(new Date()).toDate()
    if (currentShift.weeklySchedule != null && !currentShift.weeklySchedule[days[(date).getDay()]]) {
      generateAlert = false
    }
    // }
    // send email
    if (generateAlert) {
      let productionLineId = productionLine.id;
      let data = {
        productionLineId,
        value
      }
      if (machine)
        data.machine = machine
      let token = await AuthService.getToken()
      let url = apis[api]
      fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(data)
      })
    }
  }
};

export const forceStopShiftEmail = async ({ productionLine, currentShift, machine }) => {
  if (currentShift != null) {
    let generateAlert = true
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    // if (productionLine.shifts.length > 0)
    // {
    let date = moment(new Date()).toDate()
    if (currentShift.weeklySchedule != null && !currentShift.weeklySchedule[days[(date).getDay()]]) {
      generateAlert = false
    }
    // }
    // send email
    if (generateAlert) {
      let productionLineId = productionLine.id;
      let currentShiftName = currentShift.name
      let data = {
        productionLineId,
        currentShiftName
      }
      if (machine)
        data.machine = machine
      let token = await AuthService.getToken()
      let url = apis['emailOnStopShift']
      fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(data)
      })
    }
  }
};
export const emailOnLineSettingsChange = async ({ productionLine, type, currentShift, machine }) => {
  if (currentShift != null) {
    let generateAlert = true
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    // if (productionLine.shifts.length > 0)
    // {
    let date = moment(new Date()).toDate()
    if (currentShift.weeklySchedule != null && !currentShift.weeklySchedule[days[(date).getDay()]]) {
      generateAlert = false
    }
    // }
    // send email
    if (generateAlert) {
      let productionLineId = productionLine.id;
      let currentShiftName = currentShift.name
      let data = {
        productionLineId,
        type,
        currentShiftName
      }
      if (machine)
        data.machine = machine

      let token = await AuthService.getToken()
      let url = apis['emailOnLineSettingsChange']
      fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(data)
      })
      // return functions.httpsCallable('emailOnLineSettingsChange')(data);
    }
  }
};

export const deleteMachine = async (params) => {
  try {
    functions.httpsCallable('deleteMachine')(params);
  } catch (error) {
    console.log(error)
    return false;
  }
};


export const generateManualStatsForShift = async (params) => {
  try {
    let token = await AuthService.getToken()
    let url = apis['generateManualStatsForShift']
    let stats = await fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(params)
    })
    stats = await stats.json()
    return { 'data': stats };
  } catch (error) {
    console.log(error)
    return false;
  }
};

export const accountCreationAlert = async ({ email, password, role, lines, updated, isOwn }) => {
  let data = { email, password, role, lines, updated, isOwn }
  try {
    let token = await AuthService.getToken()
    let url = apis['accountCreationAlert']
    let res = await fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(data)
    })
    console.log(res)
  } catch (error) {
    console.log(error)
  }

  // return functions.httpsCallable('accountCreationAlert')({
  //   email, password, role, lines, updated: updated ? true : false, isOwn: isOwn ? true : false
  // });
};

