import request from '@/utils/request';
import { update, read } from '@/services/firestore';
import AuthService from '../utils/AuthProvider'
import config from '@/../config';

export async function query() {
  return request('/api/users');
}

export async function queryCurrent() {
  return request('/api/currentUser');
}
const apis = config.usersApis
/*
const apis = {
  'changePassword':'https://czwqtiwck52msmr2oo6e66koxa0ikurm.lambda-url.us-east-2.on.aws/',

}
*/
export const changePassForNewUser = async (pass) => {
  let token = await AuthService.getToken()
  let url = apis['changePassword']
  let response = await fetch(url, {
    method:'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization' : `Bearer ${token}`},
    body: JSON.stringify({
      pass
    })
  })
  return response.status
   // return functions.httpsCallable('changeNewUserPass')({ newPass });
};

export const isPassResetReqd = async () => {
  try {
    const userToken = await AuthService.fetchAuthenticated();
    // console.log('User Claims', userToken.claims);
    return {'passReset': (userToken['custom:requirePassReset'] && Number(userToken['custom:requirePassReset'])) || false, 'hashes': userToken['custom:passwordHashes'] ? JSON.parse(userToken['custom:passwordHashes']) : []}
  }
  catch(err){
    throw err;
  }
};
