import slugify from 'slugify';

export const generateId = (prefix, {min, max, slug} = {
  min:100000000, max:999999999, slug:false
}) => {
  if(slug) return (slugify(slug.substring(0, 13)) + '_' + getRandomString(5)).toLowerCase();
  else return (prefix + getRandomInt(min, max)).toUpperCase();
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getRandomString(length) {
  var s = '';
  do {
    s += Math.random()
      .toString(36)
      .substr(2);
  } while (s.length < length);
  s = s.substr(0, length);

  return s;
}