import config from '../../config';

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

var poolData = {
  UserPoolId: config.cognitoConfiguration.aws_user_pools_id,
  ClientId: config.cognitoConfiguration.aws_user_pools_web_client_id
};
var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

function asyncAuthenticateUser(cognitoUser, cognitoAuthenticationDetails) {
  return new Promise(function (resolve, reject) {
    cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
      onSuccess: resolve,
      onFailure: reject,
      newPasswordRequired: resolve
    })
  })
}

function asyncForgotPassword(cognitoUser) {
  return new Promise(function (resolve, reject) {
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
      newPasswordRequired: resolve
    })
  })
}

function asyncConfirmPassword(cognitoUser, code, password) {
  return new Promise(function (resolve, reject) {
    cognitoUser.confirmPassword(code, password, {
      onSuccess: resolve,
      onFailure: reject,
    })
  })
}

function asyncGetSession(cognitoUser) {
  return new Promise(function (resolve, reject) {
    cognitoUser.getSession((err, session) => {
      if (err)
        reject(err)
      else resolve(session)
    })
  })
}

function asyncGetAttributes(cognitoUser) {
  return new Promise(function (resolve, reject) {
    cognitoUser.getUserAttributes((err, attributes) => {
      if (err)
        reject(err)
      else {
        attributes = attributes.reduce((total, curr) => {
          total[curr.Name] = curr.Value
          return total
        }, {})
        resolve(attributes)
      }
    })
  })
}

class AuthService {
  constructor() {
  }


  fetchLogin = async (email, password) => {
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      {
        Username: email,
        Password: password,
      });

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });
    const user = await asyncAuthenticateUser(cognitoUser, authenticationDetails)
    return user
  };

  fetchForgotPassword = async(email) => {
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });
    await asyncForgotPassword(cognitoUser)
  }

  fetchConfirmPassword = async(email, code, password) => {
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
    });
    await asyncConfirmPassword(cognitoUser, code, password)
  }

  fetchAuthenticated = async () => {
    try {
      let cognitoUser = userPool.getCurrentUser()
      let session = await asyncGetSession(cognitoUser)
      return session.idToken.payload
    }
    catch (err) {
      console.log(err)
      return null
    }
  };

  fetchCurrentUser = async () => {
    try {
      let cognitoUser = userPool.getCurrentUser()
      return cognitoUser
    }
    catch (err) {
      console.log(err)
      return null
    }
  };

  fetchLogout = () => {
    return userPool.getCurrentUser().signOut()
  };
  getToken = async () => {
    let cognitoUser = userPool.getCurrentUser()
    let session = await asyncGetSession(cognitoUser) 
    return session.idToken.jwtToken
  };
}
let service = new AuthService()
export default service;