import AuthService from '../utils/AuthProvider'
import config from '@/../config';

const apis = config.plantApis
/*
const apis = {
  'AccessControlUpdate':'https://b22nebuootkp356bvad6enemya0anyop.lambda-url.us-east-2.on.aws/'
}
*/
//add new user through transactional check to avoid duplication
export const addNewUser = async ({ plantId, accessControl, email }) => {
  try {
    if (email)
    {
      const doesExist = accessControl.filter(userObj => userObj.email === email);
      if (doesExist.length > 1)
        throw 'This user already exists';
    }
    let token = await AuthService.getToken()
    let url = apis['AccessControlUpdate']
     await fetch(url, {
      method:'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization' : `Bearer ${token}` },
      body: JSON.stringify({
        accessControl,
        _id: plantId
      })
    })
    return true;
  } catch (error) {
    throw new Error(error);
  }
};
