import moment from 'moment-timezone';
import { notification } from 'antd';
import { addReason } from '@/services/firebase';
import { list, create, update, remove } from '@/services/firestore';
import { isPlannedowntimeLog } from '../utils/downtime';

const namespace = 'disconnectLog';
export default {
  namespace,

  state: {
    selectedDisconnection: false,
    loadingList: false,
    list: [],
  },

  effects: {
    *list({ payload }, { call, put, takeEvery, take, select }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });

      const machine = yield select((state) => state.productionLine.machine);
      // create subscription
      payload.params.machine = machine ? machine.machineName : null
      const service = yield call(list, {
        module: namespace,
        orderBy: ['started', 'desc'],
        ...payload,
      });

      function* push(response) {
        let list = null
        console.log(response)
        if (response.operationType)
        {
          if (!(moment(response.started).isAfter(moment(payload.conditions.started['$gte'])) && 
          moment(response.started).isBefore(moment(payload.conditions.started['$lte']))))
            return
            
          response.id = response['_id']
          list = yield select(state => state.disconnectLog.list);
          list = JSON.parse(JSON.stringify(list))
   
          let index = list.findIndex(x => x.id == response.id)
          if (index > -1)
          {
            if (response.operationType == 'delete')
              list.splice(index, 1);
            else
            {
              delete response['operationType']
              list[index] = response
            }
          }
          else {
            delete response['operationType']
            let insertLast = true
            for (let i in list) {
              if (moment(list[i].started).isBefore(moment(response.started)))
              {
                insertLast = false
                list.splice( i, 0, response );
                break
              }
            }
            if (insertLast)
              list.push(response)         
          }
        }
        else
          list = Array.isArray(response)  ? response : [];

        yield put({
          type: 'saveList',
          payload: list,
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    // Add reason to disconnect log
    *updateLogWithReason({ payload }, { call, put, select }) {
      const { logs } = yield select((state) => ({
        logs: state[namespace].list,
      }));
      const logToChange = logs.find((log) => log.id === payload.id);
      const machine = yield select((state) => state.productionLine.machine);
      if (machine)
        payload.machine = machine.machineName
      const response = yield call(addReason, {
        ...payload,
        collecitonId: namespace
      });
      if (response) {
        //if changing to "planned downtime" or changing from "planned downtime"
        //in both cases regenerate stats for that shift
        if (isPlannedowntimeLog(payload) || isPlannedowntimeLog(logToChange || {})) {
          yield put({ type: 'downtimeLog/generateStatsForTime', payload });
        } else {
          let desc = 'For current disconnection';
          if (payload.ended) {
            desc =
              'For ' +
              moment(payload.started.toDate()).format('h:mm:ss a') +
              ' - ' +
              moment(payload.ended.toDate()).format('h:mm:ss a');
          }

          notification['success']({
            message: 'Stoppage reason logged !',
            description: payload.reason + ' - ' + desc,
          });
        }
      }
    },
    *unsubscribe(_, { put }) {
      yield put({
        type: `clear:${namespace}/list`,
      });
      yield put({
        type: `clear:${namespace}/fetchList`,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        loadingList: false,
        list: [],
      };
    },
  },
};
