import { queryNotices } from '@/services/api';

export default {
  namespace: 'global',

  state: {
    collapsed: true,
    notices: [],
  },

  effects: {
    *fetchNotices(_, { call, put, select }) {
      // const data = yield call(queryNotices);
      // yield put({
      //   type: 'saveNotices',
      //   payload: data,
      // });
      // const unreadCount = yield select(
      //   state => state.global.notices.filter(item => !item.read).length
      // );
      // yield put({
      //   type: 'user/changeNotifyCount',
      //   payload: {
      //     totalCount: data.length,
      //     unreadCount,
      //   },
      // });
    },
    *clearNotices({ payload }, { put, select }) {
      yield put({
        type: 'saveClearedNotices',
        payload,
      });
      const count = yield select(state => state.global.notices.length);
      const unreadCount = yield select(
        state => state.global.notices.filter(item => !item.read).length
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: count,
          unreadCount,
        },
      });
    },
    *changeNoticeReadState({ payload }, { put, select }) {
      const notices = yield select(state =>
        state.global.notices.map(item => {
          const notice = { ...item };
          if (notice.id === payload) {
            notice.read = true;
          }
          return notice;
        })
      );
      yield put({
        type: 'saveNotices',
        payload: notices,
      });
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: notices.length,
          unreadCount: notices.filter(item => !item.read).length,
        },
      });
    },
  },

  reducers: {
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveNotices(state, { payload }) {
      return {
        ...state,
        notices: payload,
      };
    },
    saveClearedNotices(state, { payload }) {
      return {
        ...state,
        notices: state.notices.filter(item => item.type !== payload),
      };
    },
  },

  subscriptions: {
    setup({ history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      let loadScript = ( url, callback ) => {
        var script = document.createElement( "script" )
        script.type = "text/javascript";
        if(script.readyState) {  // only required for IE <9
          script.onreadystatechange = function() {
            if ( script.readyState === "loaded" || script.readyState === "complete" ) {
              script.onreadystatechange = null;
              callback();
            }
          };
        } else {  //Others
          script.onload = function() {
            callback();
          };
        }
      
        script.src = url;
        document.getElementsByTagName( "head" )[0].appendChild( script );
      }
    
      return history.listen(({ pathname, search }) => {
        // let widget1 = document.getElementsByName('widget-1')
        // let widget2 = document.getElementsByName('widget-2')
        let temp = document.querySelectorAll('[id=launcher-frame]');
        temp.forEach(node => node.style.visibility = 'hidden')
        if (true){
        
          // if (!widget1.length)
          // {
            window.fwSettings={
            'widget_id':62000000625
            };
            !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
            // const script = document.createElement("script");
            loadScript('https://widget.freshworks.com/widgets/62000000625.js', function() {
              // setTimeout(() => {
              //   let elem = (document.getElementById('launcher-frame'))
              //   if (elem)
              //     elem.setAttribute('name', 'widget-1')
              // },1000)
            });
          // }
          // else {
          //   widget1[0].style.visibility = 'visible'
          // }
        
        // else if (location.pathname.includes('/line-settings')) {
        //   // if (!widget2.length)
        //   // {
        //     window.fwSettings={
        //     'widget_id':62000000918
        //     };
        //     !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
        //     // const script = document.createElement("script");
        //     loadScript('https://widget.freshworks.com/widgets/62000000918.js', function() {
        //       // setTimeout(() => {
        //       //   let elem = (document.getElementById('launcher-frame'))
        //       //   if (elem)
        //       //     elem.setAttribute('name', 'widget-2')
        //       // },1000)
        //     });
        //   // }
        //   // else {
        //   //   widget2[0].style.visibility = 'visbile'
        //   // }
        // }
        
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
    }
  });
    }
  },
};
