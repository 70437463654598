import { currentUser } from '@/services/firebase';
// import { currentShift, getShiftByRange } from '@/utils/shifts';
import { notification } from 'antd';
import router from 'umi/router';
import { list, create, update } from '@/services/firestore';
import { isSuperAdminByEmail } from '@/utils/authority';
import { addNewUser } from '@/services/plant';
import moment from 'moment-timezone';

const namespace = 'plant';
export default {
  namespace,

  state: {
    loading: false,
    loadingCurrent: true,
    loadingList: false,
    loadingOperation: false,

    statusOperation: '',
    current: null,
    currentShift: null,
    list: [],
  },

  effects: {
    // set current by selecting first from localstorage,
    // if not avaible then from the state list, but dont overwrite
    *setDefaultCurrent({ payload }, { put, select }) {
      const cachedCurrent = localStorage.getItem('selected-plant');
      if (cachedCurrent) {
        const list = yield select(state => state.plant.list);
        if (list.filter(item => item.id === cachedCurrent).length > 0) {
          yield put({
            type: 'setById',
            payload: { ...payload, plantId: cachedCurrent },
          });
          return;
        }
      }

      const current = yield select(state => state.plant.current);
      if (!current) {
        // dont override
        yield put({
          type: 'setById',
          payload,
        });
      }
    },
    // set plant to current by given id
    *setById({ payload }, { select, put, takeEvery, take, call }) {
      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });
      const list = yield select(state => state.plant.list);
      const newCurrent = list.find(item => item.id == payload.plantId);
      if (!newCurrent) {
        yield put({
          type: 'save',
          payload: {
            current: null,
            loadingCurrent: false,
          },
        });
        return;
      }
      // == SAVE TO LOCAL STORAGE TO SAVE SELECTIO
      localStorage.setItem('selected-plant', payload.plantId);
      moment.tz.setDefault(newCurrent.timezone ? newCurrent.timezone : 'Asia/Karachi');
      // list current users plant and then assign a current plant and then fire setAccessControl
      yield put({
        type: 'productionLine/list',
        payload: { ...payload, plant: newCurrent },
      });
      yield put({
        type: 'productionLine/setHallName',
        payload: '',
      });

      yield put({
        type: 'save',
        payload: {
          current: newCurrent,
          loading: false,
        },
      });

      yield put({
        type: 'login/setAccessControl',
        payload: {
          plant: newCurrent,
        },
      });
    },
    // list current users plant and then assign a current plant and then fire setAccessControl
    *list({ payload }, { call, put, takeEvery, take, select }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });

      // create subscription
      const service = yield call(list, {
        module: namespace,
        // orderBy: ['createdAt', 'desc'],
        ...payload,
      });

      const canUserAccessPlant = (accessControl, email) => {
        const user = accessControl ? accessControl.find(user => user.email === email) : false;
        return isSuperAdminByEmail(email) || user;
      };

      function* push(response) {
        try
        {
        let list = null
        if (response['_id'])
        {
          if (!response.operationType)
            return

            response.id = response['_id']
            list = yield select(state => state.plant.list);
            list = JSON.parse(JSON.stringify(list))
     
            let index = list.findIndex(x => x.id == response.id)
            if (index > -1)
            {
              if (response.operationType == 'delete')
                list.splice(index, 1);
              else
              {
                delete response['operationType']
                list[index] = response
              }
            }
            else {
              delete response['operationType']
              if (canUserAccessPlant(response?.accessControl, payload?.email))
                list.push(response)
            }
        }
        else
          list = Array.isArray(response)  ? response : [];
        // list = list.filter(
        //   item =>
        //     item.type !== 'deleted' && canUserAccessPlant(item.accessControl, payload.email)
        // )
        yield put({
          type: 'saveList',
          payload: list,
        });

        yield put({
          type: 'setDefaultCurrent',
          payload: {
            ...payload,
            plantId: list.length > 0 ? list[0].id : null,
          },
        });
      }
      catch(err) {
        alert(err)
      }
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      // yield take(`clear:${namespace}/list`);
      // service.close();
      yield put({
        type: 'clearList',
      });
    },

    *create({ payload }, { call, put, select }) {
      const user = yield select(state => state.user.currentUser);

      yield put({
        type: 'save',
        payload: { loadingOperation: true, statusOperation: '' },
      });

      try {
        const data = {
          ...payload.data,
          alertSettings: [],
          accessControl: [
            {
              email: user.email,
              role: 'admin',
            },
          ],
        };
        const response = yield call(create, {
          module: namespace,
          ...payload,
          data,
        });
        if (response) {
          notification.success({
            message: 'New Plant added',
          });

          yield put({
            type: `setById`,
            payload: { plantId: response }, // id
          });

          router.push('/plant-settings');
        }
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },

    *update({ payload }, { call, put }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingOperation: true, statusOperation: '' },
        });
        const response = yield call(update, {
          module: namespace,
          params: { id: payload.id, log: payload.log },
          data: payload.data,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
        notification.success({
          message: 'plant updated',
        });
      } catch (error) {
        console.error('update error', error);
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },

    //add new user through transactional check
    *addUser({ payload }, { call, put }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingOperation: true, statusOperation: '' },
        });
        const response = yield call(addNewUser, {
          plantId: payload.id,
          accessControl: payload.data.accessControl,
          email: payload.data.email,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
        notification.success({
          message: 'plant updated',
        });
      } catch (error) {
        console.error('update error', error);
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },

    *unsubscribe(_, { put }) {
      yield put({ type: 'CANCEL_WATCH' });
      yield put({ type: `clear:${namespace}/list` });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        list: [],
        loadingList: false,
      };
    },
    clear() {
      return {
        current: null,
        loading: false,
      };
    },
  },
};
