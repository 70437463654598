import { routerRedux } from 'dva/router';
import { stringify } from 'qs';
import { notification } from 'antd';
import { setAuthority, getAuthority, isSuperAdminByEmail, setRoles } from '@/utils/authority';
import { getPageQuery, delay } from '@/utils/utils';
import { reloadAuthorized } from '@/utils/Authorized';
import AuthService from '../utils/AuthProvider';
import { read } from '@/services/firestore';
import defaultRoles from '@/../config/defaultRoles';
import { isPassResetReqd, changePassForNewUser } from '@/services/user';
import { accountCreationAlert } from '@/services/functions'
const namespace = 'login';
const { fetchLogin, fetchLogout, fetchAuthenticated } = AuthService
export default {
  namespace,

  state: {
    status: 'loading',
    statusMessage: '',
    authority: 'guest',
    userEmail: null, // holds user email temporarily between login stages
    passResetReqd: false,
    loadingPassChange: false,
    passwordHashes: []
  },

  subscriptions: {
    async onAuthStateChanged({ dispatch }) {
      let profile = await fetchAuthenticated()
      if (profile) {
        dispatch({
          type: 'loginCurrentUser',
          payload: {
            email: profile.email
          }
        })
      }
      else {
        dispatch({
          type: 'changeLoginStatus',
          payload: {
            status: '',
            statusMessage: '',
            currentAuthority: 'guest',
            type: 'account',
          },
        });
        if (window.location.pathname !== '/user/login' && window.location.pathname !== '/user/callback') {
          dispatch(
            routerRedux.push('/user/login?redirect=' + encodeURIComponent(window.location.href))
          );
        }
      }

    },
  },

  effects: {
    // Proceed to login user after auth change
    *loginCurrentUser({ payload }, { put, call, takeLatest, take, select }) {
      yield put({
        type: 'save',
        payload: { status: 'loading', userEmail: payload.email },
      });

      yield put({
        type: 'user/fetchCurrent',
      });

      //check if pass reset reqd
      yield put({
        type: 'handlePassResetReqd',
      });

      // list current users plant and then assign a current plant and then fire setAccessControl
      yield put({
        type: 'plant/list',
        payload,
      });

      yield put({
        type: 'redirectAfterLogin',
      });
    },

    //handles to se whether pass reset is reqd (if first sign-in)
    *handlePassResetReqd({ payload }, { put, call, takeLatest, take, select }) {
      try {
        const isReqd = yield call(isPassResetReqd);
        yield put({
          type: 'save',
          payload: { passResetReqd: isReqd.passReset, passwordHashes: isReqd.hashes },
        });
      } catch (e) {
        console.log('Custom Claims Error:', e);
      }
    },

    //change new user's password
    *changeNewUserPass({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'save',
          payload: {
            loadingPassChange: true,
          },
        });
        // const email = yield select(state => state.login.userEmail);
        let status = yield call(changePassForNewUser, payload.newPass);
        if (status == 200) {
          // accountCreationAlert({email, password: payload.newPass, isOwn: true})
          yield put({
            type: 'save',
            payload: {
              loadingPassChange: false,
              passResetReqd: false,
            },
          });
          notification.success({
            message: 'Password updated sucessfully. Please login Back Again.',
          });
          yield put({
            type: 'login/logout',
          });
        }
        else if (status == 403) {
          notification.error({
            message: 'Password Already Used',
          });
          yield put({
            type: 'save',
            payload: {
              loadingPassChange: false,
            },
          });
        }
        else {
          notification.error({
            message: 'Update Password Failed',
          });
          yield put({
            type: 'save',
            payload: {
              loadingPassChange: false,
            },
          });
        }
      } catch (error) {
        console.log('Error:', error);
        yield put({
          type: 'save',
          payload: {
            loadingPassChange: false,
          },
        });

        notification.error({
          message: 'An unexpected error occurred!',
        });
      }
    },

    // set user authority and login status. Triggered from production line setById
    *setAccessControl({ payload }, { put, call, takeLatest, take, select }) {
      const userEmail = yield select(state => state.login.userEmail);
      const accessControl = payload.plant.accessControl;
      const currentUser = accessControl.find(item => item.email === userEmail);
      try {
        if (!isSuperAdminByEmail(userEmail)) {
          const responseRole = defaultRoles.find(x => x.role == currentUser.role) ?
            defaultRoles.find(x => x.role == currentUser.role)
            : yield call(read, {
              module: 'roles',
              conditions: {
                role: currentUser.role,
                plantId: payload.plant.id,
              },
              stream: false,
            });


          setRoles(defaultRoles);
          if (responseRole) {
            setRoles([responseRole]);
          }
        }

        let currentUserAccessControl = accessControl.filter(item => item.email === userEmail);
        if (currentUserAccessControl.length < 1 && !isSuperAdminByEmail(userEmail))
          throw new Error('User access data not available');
        currentUserAccessControl = currentUserAccessControl[0];

        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: 'ok',
            currentAuthority: isSuperAdminByEmail(userEmail)
              ? 'superAdmin'
              : currentUserAccessControl.role,
            type: 'account',
          },
        });

        reloadAuthorized();
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: 'error',
            statusMessage: error && error.message && error.message,
            currentAuthority: 'guest',
            type: 'account',
          },
        });
      }
    },
    *login({ payload }, { call, put }) {
      try {
        const response = yield call(fetchLogin, payload.userName.toLowerCase(), payload.password);
        yield put({
          type: 'loginCurrentUser',
          payload: { email: payload.userName.toLowerCase() }
        })
      } catch (error) {
        console.log('error response --->', error);
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: 'error',
            statusMessage: error && error.message,
            currentAuthority: 'guest',
            type: 'account',
          },
        });
        yield put({
          type: 'unsubscribe',
        });
      }
    },

    *redirectAfterLogin(_, { put, select }) {
      // const attemptedUrl = yield select(state => state.app.attemptedUrl)
      // yield put(routerRedux.push({ pathname: attemptedUrl }))
      reloadAuthorized();
      const urlParams = new URL(window.location.href);
      const params = getPageQuery();
      let { redirect } = params;

      if (redirect) {
        const redirectUrlParams = new URL(redirect);
        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);
          if (redirect.match(/^\/.*#/)) {
            redirect = redirect.substr(redirect.indexOf('#') + 1);
          }
        } else {
          redirect = null;
        }
      }
      yield put(routerRedux.replace(redirect || '/'));
    },

    *logout(_, { put, call }) {
      const response = yield call(fetchLogout);
      yield put({
        type: 'unsubscribe',
      });

      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: false,
          currentAuthority: 'guest',
        },
      });

      reloadAuthorized();
      // redirect
      if (window.location.pathname !== '/user/login') {
        yield put(
          routerRedux.replace({
            pathname: '/user/login',
            search: stringify({
              // redirect: window.location.href,
            }),
          })
        );
      }
    },
    *unsubscribe(_, { put }) {
      yield put({ type: `clear:${namespace}/loginCurrentUser` });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.currentAuthority);
      return {
        ...state,
        authority: payload.currentAuthority,
        status: payload.status,
        statusMessage: payload.statusMessage && payload.statusMessage,
        type: payload.type,
      };
    },
  },
};