import AuthService from './AuthProvider';

let authorityState = ['login'];
let roles = {
  superAdmin: [
    'view-all',
    'end-shift',
    'create-plant',
    'delete-plant',
    'create-productionLine',
    'delete-productionLine',
    'create-dynamicAdjustments-rework',
    'create-dynamicAdjustments-add-rejections',
    'view-leaderboard',
    'view-integration-details',
    'audit-trail-access',
    'product-write-access',
    'edit-line-settings',
    'edit-role',
    'update-downtimeLog',
    'add-validation-access',
    'machine-access',
    'manual-entry',
    'crfs-enabled',
    'crfs-hilal-maintenance',
    'crfs-hilal-merged',
    'line-performance-monitor',
    'line-level-board',
    'change-date-filters-operator-page',
    'force-shift-stop-write',
    'support-button-write',
    'reports-access',
    'change-date-filters-line-performance',
    'change-date-filters-line-level-board',
    'product-change-line-operator',
    'rework-access',
    'add-rejections-access',
    'merge-downtime-access',
    'demerge-downtime-access',
    'mark-downtime-access',
    'change-date-filters-downtime-log',
    'edit-downtime-access',
    'line-information-access',
    'shift-access',
    'planned-downtime-access',
    'product-access',
    'weekly-access',
    'downtime-setting-access',
    'daily-target-access',
    'manual-entry-access',
    'plant-user-access',
    'plant-role-access',
    'plant-alerts-access',
    'site-champion-access',
    'generate-audit-report',
    'help-support-access',
    'user-export-report',
    'weekly-target-access',
    'weekly-target-page',
    'force-stop-page',
    'view-batchwise-page',
    'start-stop-production'
  ],
  guest: ['login'],
};

// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority(str) {
  const authorityString = typeof str === 'undefined' ? authorityState : str;
  // authorityString could be admin, "admin", ["admin"]
  let authority = authorityString;
  if (typeof authority === 'string') {
    return [authority];
  }
  return authority;
}
export function setAuthority(authority) {
  const proAuthority = typeof authority === 'string' ? roles[authority] : roles[authority[0]];
  authorityState = proAuthority;
}

// @todo implement in router
export function userCan(action) {
  return getAuthority().includes(action);
}

export async function isSuperAdmin() {
  const user = await AuthService.fetchAuthenticated();
  return user && isSuperAdminByEmail(user.email);
}

/**
 * Anyone with @atompoint.com or @procheck.pk email is considered super admin
 * @param {string} email user's email address
 */
export function isSuperAdminByEmail(email) {
  return  email && email.match(/\@atompoint\.com|\@procheck\.pk/i) ? true : false;
}

/**
 * Set roles object above only if it doesn't contain a role from roles collection.
 * @param {object} roles plant's roles collection
 */
export function setRoles(rolesCollection) {
  rolesCollection.forEach(item => {
    if (!roles[item.role]) roles[item.role] = item.capabilities;
  });
}
