import auth0 from 'auth0-js';

class Auth {
    tokenRenewalTimeout;
    userProfile;
  constructor() {
    this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      domain: 'procheck.us.auth0.com',
      audience: 'https://procheck.us.auth0.com/userinfo',
      clientID: 'ov5aWGsYI98L1Pmz21f9tJXbDjfDz3Mj',
      redirectUri: 'http://localhost:8000/user/callback',
      responseType: 'token id_token',
      scope: 'openid profile'
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.scheduleRenewal();

  }
  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const delay = expiresAt - Date.now();
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
    }
    }
    renewToken() {
        this.auth0.checkSession({}, (err, result) => {
            if (err) {
                console.log(err);
            } else {
                this.setSession(result);``
            }
            }
        );
        }
  getProfile() {
    let profile = localStorage.getItem('profile')
    return profile ?  JSON.parse(profile) : null;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  signIn() {
    this.auth0.authorize();
  }


  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
        this.setSession(authResult);
        resolve();
      });
    })
  }
  setSession = (authResult) => {
    //set the time that the access token will expire
    const expiresAt = JSON.stringify(
        authResult.idTokenPayload.exp * 1000
    );
    console.log(authResult)
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("profile", JSON.stringify(authResult.idTokenPayload));

  };

  signOut() {
    // clear id token, profile, and expiration
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    this.auth0.logout({
        clientID: 'ov5aWGsYI98L1Pmz21f9tJXbDjfDz3Mj',
        returnTo: "http://localhost:8000/",
      });
      
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;
  }
}
const auth0Client = new Auth();

export default auth0Client;