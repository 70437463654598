import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'disconnectLog', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/disconnectLog.js').default) });
app.model({ namespace: 'downtimeLog', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/downtimeLog.js').default) });
app.model({ namespace: 'dynamicAdjustments', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/dynamicAdjustments.js').default) });
app.model({ namespace: 'global', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/global.js').default) });
app.model({ namespace: 'headcount', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/headcount.js').default) });
app.model({ namespace: 'list', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/list.js').default) });
app.model({ namespace: 'login', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/login.js').default) });
app.model({ namespace: 'menu', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/menu.js').default) });
app.model({ namespace: 'plannedDowntimes', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/plannedDowntimes.js').default) });
app.model({ namespace: 'plant', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/plant.js').default) });
app.model({ namespace: 'productionLine', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/productionLine.js').default) });
app.model({ namespace: 'productLog', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/productLog.js').default) });
app.model({ namespace: 'products', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/products.js').default) });
app.model({ namespace: 'project', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/project.js').default) });
app.model({ namespace: 'reasons', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/reasons.js').default) });
app.model({ namespace: 'roles', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/roles.js').default) });
app.model({ namespace: 'setting', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/setting.js').default) });
app.model({ namespace: 'shifts', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/shifts.js').default) });
app.model({ namespace: 'user', ...(require('/codebuild/output/src285808722/src/oee-frontend/src/models/user.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
