import { updateProductionLine, getProductionLineById, currentUser, fetchSensorsData } from '@/services/firebase';
import { currentShift, getShiftByRange } from '@/utils/shifts';
import { notification } from 'antd';
import router from 'umi/router';
import moment from 'moment-timezone';
import { list, create, createMachine } from '@/services/firestore';
import { isSuperAdminByEmail } from '@/utils/authority';

const namespace = 'productionLine';
export default {
  namespace,

  state: {
    loading: false,
    loadingCurrent: true,
    loadingList: false,
    loadingOperation: false,
    speed: 0,
    statusOperation: '',
    hall: '',
    halls: [],
    machine: null,
    current: null,
    currentShift: null,
    originalList: [],
    list: [],
  },

  effects: {
    // set current by selecting first from localstorage,
    // if not avaible then from the state list, but dont overwrite
    *setDefaultCurrent({ payload }, { put, select }) {
      const cachedCurrent = localStorage.getItem('selected-productionLine');
      if (cachedCurrent) {
        const list = yield select(state => state.productionLine.list);
        if (list.filter(item => item.id === cachedCurrent).length > 0) {
          yield put({
            type: 'setById',
            payload: cachedCurrent,
          });
          return;
        }
      }

      yield put({
        type: 'setById',
        payload,
      });
    },
    // set production line to current by given id
    *setById({ payload }, { select, put }) {
      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });

      const list = yield select(state => state.productionLine.list);
      const oldCurrent = yield select(state => state.productionLine.current)
      const newCurrent = list.find(item => item.id == payload);
      if (!newCurrent) {
        yield put({
          type: 'save',
          payload: {
            current: null,
            loadingCurrent: false,
            machine: null
          },
        });
        return;
      }
      // == SAVE TO LOCAL STORAGE TO SAVE SELECTIO
      localStorage.setItem('selected-productionLine', payload);
      if (JSON.stringify(oldCurrent) != JSON.stringify(newCurrent)) {
        let data = oldCurrent && (oldCurrent.id == newCurrent.id) ? {
          current: newCurrent,
          loading: false,
        } : {
          current: newCurrent,
          loading: false,
          machine: null
        }
        yield put({
          type: 'save',
          payload: data,
        });
        if (JSON.stringify(oldCurrent && oldCurrent.machines ? oldCurrent.machines : null) != JSON.stringify(newCurrent && newCurrent.machines ? newCurrent.machines : null)) {
          yield put({
            type: 'refreshMachine',
          });
        }
        // set current shift here
        yield put({
          type: 'shifts/setCurrentShift',
        });
      }

    },
    *setMachine({ payload }, { select, put }) {
      const line = yield select(state => state.productionLine.current);
      const machine = line && line.machines ? line.machines.find(x => x.machineName == payload) : null
      yield put({
        type: 'save',
        payload: {
          machine,
        },
      });
    },
    *refreshMachine({ }, { select, put }) {
      const line = yield select(state => state.productionLine.current);
      let machine = yield select(state => state.productionLine.machine);
      machine = line && line.machines ? line.machines.find(x => x.machineName == machine.machineName) : null
      yield put({
        type: 'save',
        payload: {
          machine,
        },
      });
    },
    *setHallName({ payload }, { select, put }) {
      if (payload == '') {
        yield put({
          type: 'save',
          payload: { hall: '' },
        });
        return
      }
      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });

      const originalList = yield select(state => state.productionLine.originalList);
      const newList = originalList.filter(item => item.hallName == payload);

      yield put({
        type: 'saveList',
        payload: newList,
      });
      yield put({
        type: 'save',
        payload: {
          loadingCurrent: false, hall: payload, machine: null
        },
      });
      yield put({
        type: `setById`,
        payload: newList.length > 0 ? newList[0].id : '', // id
      });
      return;
    },
    // list current users production and then assign a current line and then fire setAccessControl
    *list({ payload }, { call, put, takeEvery, take, select }) {

      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });
      if (!payload.plant)
        return
      payload.conditions = { '_id': payload.plant.id }
      // create subscription
      const service = yield call(list, {
        module: namespace,
        // orderBy: ['createdAt', 'desc'],
        ...payload,
      });

      const userEmail = yield select(state => state.login.userEmail);
      const canUserAccessLine = ({ plant }, lineId, userEmail) => {
        const user = plant.accessControl.find(user => user.email === userEmail);
        return (
          isSuperAdminByEmail(userEmail) ||
          (user && user.lines && user.lines.find(line => line.id === lineId))
        );
      };
      function* push(response) {
        let list = []
        let currentPlant = yield select(state => state.plant.current);
        let payloadToUpdate = {}
        if (response.operationType) {
          response.id = response['_id']
          list = yield select(state => state.productionLine.list);
          list = JSON.parse(JSON.stringify(list))

          let index = list.findIndex(x => x.id == response.id)
          if (index > -1) {
            if (response.operationType == 'delete')
              list.splice(index, 1);
            else {
              delete response['operationType']
              list[index] = response
            }
          }
          else if (response.plantId === currentPlant._id
            && canUserAccessLine({ plant: currentPlant }, response.id, userEmail)
          ) {
            delete response['operationType']
            list.push(response)
          }
        }
        else if (Array.isArray(response)) {
          list = response;
          let halls = [...new Set(list.map(item => item.hallName))];
          payloadToUpdate.halls = halls;
          payloadToUpdate.originalList = list;

          yield put({
            type: 'save',
            payload: payloadToUpdate,
          });
        }
        // list = list.filter(item => payload.plant.productionLines.find(line => line.id === item.id))
        // .filter(
        //   item => item.type !== 'deleted' && canUserAccessLine(payload, item.id, userEmail)
        // )


        yield put({
          type: 'saveList',
          payload: list,
        });

        yield put({
          type: 'setDefaultCurrent',
          payload: list.length > 0 ? list[0].id : null,
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },
    *fetchSensorsData({ payload }, { call, put, takeEvery, take }) {
      yield put({
        type: `clear:${namespace}/fetchSensorsData`,
      });


      let fetchSensorsDataSingle = yield call(fetchSensorsData, payload);
      function* pushSpeed(response) {
        yield put({
          type: 'save',
          payload: {
            ...response,
            loading: false
          },
        });
      }

      yield takeEvery(fetchSensorsDataSingle, pushSpeed);
      yield take(`clear:${namespace}/fetchSensorsData`);
      fetchSensorsDataSingle.close()
    },
    *create({ payload }, { call, put, select }) {
      yield put({
        type: 'save',
        payload: { loadingOperation: true, statusOperation: '' },
      });

      const currentPlant = yield select(state => state.plant.current);

      try {
        const data = {
          ...payload.data,
          plantName: currentPlant.plantName,
          plantId: currentPlant.id,
          dayStartAdjustment: { time: '', minus: 0, add: 0 },
          midDayResetAdjustment: { time: '', minus: 0, add: 0 },
          lineSpeed: 0,
          alertSettings: [],
          shifts: [],
          status: {
            status: 'disconnected',
            time: new Date(),
          },
        };
        const response = yield call(create, {
          module: namespace,
          ...payload,
          data,
        });

        if (response) {
          if (!currentPlant.productionLines) currentPlant.productionLines = [];

          currentPlant.productionLines.push({
            id: response,
            lineName: data.lineName,
            hallName: data.hallName,
          });

          yield put({
            type: 'plant/update',
            payload: {
              id: currentPlant.id,
              data: currentPlant,
            },
          });

          notification.success({
            message: 'New Production line added',
          });

          yield put({
            type: `setById`,
            payload: response, // id
          });

          router.push('/line-settings');
        }
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },
    *createMachine({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingOperation: true, statusOperation: '' },
        });
        const response = yield call(createMachine, payload);
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
        notification.success({
          message: 'Machine Created',
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },
    *update({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingOperation: true, statusOperation: '' },
        });
        if (payload.log) {
          let machine = yield select(state => state.productionLine.machine);
          if (machine)
            payload.log.machine = machine.displayName
        }
        let plant = yield select(state => state.plant.current);
        payload.data.plantId = plant.id
        const response = yield call(updateProductionLine, payload);
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
        notification.success({
          message: 'Production line updated',
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },
    *unsubscribe(_, { put }) {
      yield put({ type: 'CANCEL_WATCH' });
      yield put({ type: `clear:${namespace}/list` });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        list: [],
        loadingList: false,
      };
    },
    clear() {
      return {
        current: null,
        loading: false,
      };
    },
  },
};
