export default {
  functionsApi: 'https://p7nyk5th14.execute-api.us-east-2.amazonaws.com',
  realtimeInstanceUrl: 'https://oee-uat-rts.procheck.io',
  cognitoConfiguration: {
    "aws_user_pools_id": "ap-southeast-1_DXQiZZ0fj",
    "aws_user_pools_web_client_id": "42jh7up2ijac9aleu9f63suk9d"
  },
  AwsS3BucketAccessKey:'AKIAXM6KMDYYFFA26N7B',
  AwsS3BucketSecretAccessKey:'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',
  AwsS3BucketRegion:'us-east-2',
  AwsS3BucketName:'uat-oee-s3',
  fireBaseApis :{
    'aggregateStats':'https://l3vhcpucwnfjnsof4aghmzicte0fviub.lambda-url.us-east-2.on.aws/',
  'bulkCreateLogs':'https://bgnoqa4vxkhzcwg3ookhn2dpzm0qnjrh.lambda-url.us-east-2.on.aws/',
  'downtimeLogDuplication':'https://prae5ybxsdvl6wnnqxzqldwxc40muydc.lambda-url.us-east-2.on.aws/',
  'batchNumberCreate':'https://sgu3v62hl7mn7z6f2l3q4jpxoq0vmhgm.lambda-url.us-east-2.on.aws/',
  'logsCreate':'https://4m4afp2ibxsgpz6su34ttfyrdi0vwjbu.lambda-url.us-east-2.on.aws/',
  'generateBatchwiseStatsForTime':'https://zb37tpxfdmn65zvhdid2bwm4my0qbrww.lambda-url.us-east-2.on.aws/',
  'generateStatsForTime':'https://xq7xbxawyunfpkvqmj3kohul2m0cvgud.lambda-url.us-east-2.on.aws/',
  'getBatchStats':'https://gkaksuv4rxk4j6cjwybe4mw7z40bobzd.lambda-url.us-east-2.on.aws/',
  'maintenancekpisGetData':'https://5ss2zgcvr4lbrc7dlngfgjqk7a0qsneh.lambda-url.us-east-2.on.aws/',
  'getMergeStats':'https://swc7qtpl7dteajo7ov43awayba0lrcxv.lambda-url.us-east-2.on.aws/',
  'getProducts':'https://vkout42vvsskzhvwmpyvfev5su0lfrxo.lambda-url.us-east-2.on.aws/',
  'getSensorsData':'https://sd7y2cdlfotvpp6s5ddm5zz4cm0oqxwz.lambda-url.us-east-2.on.aws/',
  'getStats':'https://sdyrjpdtvnicva4wahz2j7m5m40aygyv.lambda-url.us-east-2.on.aws/',
  'logsList':'https://zyzqh2cvgzeaxgiry2yz3oyqfe0fsmzu.lambda-url.us-east-2.on.aws/',
  'mergeOrDemergeDowntimeLog':'https://a55oc4cng4l5ktyzwxhn574yei0xxvmv.lambda-url.us-east-2.on.aws/',
  'sendSapNotification':'https://jhcyoqjdn2rqztdtzvatymtimi0yjjiv.lambda-url.us-east-2.on.aws/',
  'setPrimaryKey':'https://mle5pzfun7thbxc2jdhrayp76u0gcrub.lambda-url.us-east-2.on.aws/',
  'UpdateBatches':'https://dzgo5uitovgu44f5x5yy6tizl40izggs.lambda-url.us-east-2.on.aws/'
  },
  fireBaseUpdateApis :{
    'downtimeLog':'https://ekimadznvqb3e6gf3f3uw4vbnm0yrmjy.lambda-url.us-east-2.on.aws/',
    'disconnectLog':'https://ba3vfrbuibjdr344t2e7xazunu0oannj.lambda-url.us-east-2.on.aws/',
    'productionLine':'https://lkkslmebd52cy7jixh3fgiqcr40psczb.lambda-url.us-east-2.on.aws/',  
  },
  fireStoreListApis:{
    'productionLine':'https://g7qchqmu6eecddrljof7k7cp5q0shxxn.lambda-url.us-east-2.on.aws/',
    'plant':'https://o6grsi3b7axbhr5oejjo63gfm40uzzvn.lambda-url.us-east-2.on.aws/',
    'disconnectLog':'https://luf4tpmjdktzabpfcv3hrmrsru0yzlxb.lambda-url.us-east-2.on.aws/',
    'downtimeLog':'https://h63eqf4i25ud3dfjsyz5p7afhu0rtpvu.lambda-url.us-east-2.on.aws/',
    'dynamicAdjustments':'https://xzzgskiczp6i3yu7zigsxmdjz40dssyd.lambda-url.us-east-2.on.aws/',
    'headcount':'https://wtk4wtnm3vqosp4oeskrvbmdia0qubzm.lambda-url.us-east-2.on.aws/',
    'plannedDowntimes':'https://awnyzhl53yjwutzrg4fcbzzk2i0iwlsl.lambda-url.us-east-2.on.aws/',
    'productLog':'',
    'products':'https://ikbqecxjdmtj4uhsubxvkts3py0paeag.lambda-url.us-east-2.on.aws/',
    'roles':'https://ixeun6zsemhekk5xc5mgzg2piu0xmakr.lambda-url.us-east-2.on.aws/',
    'stats':'https://oocl2jy2sf2xulyvq75ccj5i3i0uvnke.lambda-url.us-east-2.on.aws/',
    'batches':'https://lbjfm4a7hwqdtss34cqhuaaeji0usocn.lambda-url.us-east-2.on.aws/',
    //'integrations':'',
    //'batchesForOperator':'',
    'mergeStats':'https://wh2qq42n766srymh42p4wsvony0besfc.lambda-url.us-east-2.on.aws/',
  },
  fireStoreCreateApis:{
    'downtimeLog':'https://xhqeqit7v6qsseitxh56ryunwa0abnmo.lambda-url.us-east-2.on.aws/',
    'dynamicAdjustments':'https://bbhcqnn43e4jcyhcvln6lxqary0vbnqs.lambda-url.us-east-2.on.aws/',
    'headcount':'https://x4wm4rkpaq2y24huqzlj3ndbb40msgsi.lambda-url.us-east-2.on.aws/',
    'plannedDowntimes':'https://xsjfvuj6bs24lx2jx4i477lahu0xjunq.lambda-url.us-east-2.on.aws/',
    'plant':'https://vorve3tq5nizylllryb3ijroqm0tcgjt.lambda-url.us-east-2.on.aws/',
    'products':'https://kdc2cyp7tdg4m2cota2gdvrd4u0xdkdc.lambda-url.us-east-2.on.aws/',
    'productionLine':'https://wrkxu25eqqoihjphionfynnv4e0pihqm.lambda-url.us-east-2.on.aws/',
    'productLog':'https://ipqvllyf5anihgx3wmkrv4twge0gvkgj.lambda-url.us-east-2.on.aws/',
    'roles':'https://6i6cnpswjhu7pjrf3fxrvo6h3y0oklit.lambda-url.us-east-2.on.aws/',
  
  
  },
  fireStoreDeleteApis:{
    'downtimelog':'https://hy7kgjxvaid7nmhhj2q4a7kl6q0trykc.lambda-url.us-east-2.on.aws/',
    'dynamicAdjustments':'https://3fy7zqveruuexb76id6a2f6zry0qmjni.lambda-url.us-east-2.on.aws/',
    'plannedDowntimes':'https://ay3kbp6y5ubooy222ehxzo4hsy0ntarp.lambda-url.us-east-2.on.aws/',
    'products':'https://gtagllrcmgl74fq67hplj7wuwm0jrvqh.lambda-url.us-east-2.on.aws/',
    'roles':'https://6t72qvvxeccfiblmqdugc6kjm40exlft.lambda-url.us-east-2.on.aws/',
    
  },
  fireStoreApis : {
    'bulkSyncMachines':'https://nwndjftkjn6cllq3isyc4l3geq0xfimz.lambda-url.us-east-2.on.aws/',
    'createMachine':'https://x7wnx42lxeu2ptxzllrocsdzwq0vvzzf.lambda-url.us-east-2.on.aws/',
    'productBatchQuery':'https://nydlkjttdysjokixgqh4lxl3ee0bnnej.lambda-url.us-east-2.on.aws/',
    'SyncShifts':'https://nt2p63r5rqihwkblljhdl3lufe0nuiih.lambda-url.us-east-2.on.aws/',
  },
  fireStoreGetApis:{
    'roles':'https://ntk7woih5hxpeffphclchctlky0txxds.lambda-url.us-east-2.on.aws/',
  },
  fireStoreUpdateApis: {
    'headcount':'https://vchvwn2bd6cppfhxvvsms7a2nu0zuatt.lambda-url.us-east-2.on.aws/',
    'plannedDowntimes':'https://4uaqqyuqltuptwqbuzuuu2abhy0jiyfb.lambda-url.us-east-2.on.aws/',
    'plant':'https://wjbfrw3chhvyckoz2ptahvjl4q0mxjit.lambda-url.us-east-2.on.aws/',
    'products':'https://7awhlme3tbvv66kntroq2oppgy0elwlz.lambda-url.us-east-2.on.aws/',
    'productionLine':'https://lkkslmebd52cy7jixh3fgiqcr40psczb.lambda-url.us-east-2.on.aws/',
    'roles':'https://wb6nb7dggjmxslf3nfnnz2nely0dfvyp.lambda-url.us-east-2.on.aws/',
    'stats':'https://q4vw7d4ofx72frrktiq6h5bdzm0kyack.lambda-url.us-east-2.on.aws/'
  },
  generalFunctionApis: {
    'accountCreationAlert':'https://4rzskbcp6ppbdh74zzkw6pfymq0vzphe.lambda-url.us-east-2.on.aws/',
    'emailOnLineSettingsChange':'https://wd66kw5yzs6zoynrun77yvtene0iqilv.lambda-url.us-east-2.on.aws/',
    'emailOnRejection':'https://sznyyq64yn6lazg55yinlcxit40qdapw.lambda-url.us-east-2.on.aws/',
    'emailOnRework':'https://677el22x47pngu3rhyygsf2lm40amxjt.lambda-url.us-east-2.on.aws/',
    'emailOnStopShift':'https://uiw2zr5dong7uvzg7lbvez46jm0awirw.lambda-url.us-east-2.on.aws/',
    'exportReport':'https://uygmdjgriixtlyimmpavraqh2i0przfz.lambda-url.us-east-2.on.aws/',
    'generateManualStatsForShift':'https://tk67og4dulhyojovezi5xlixfe0sfqwu.lambda-url.us-east-2.on.aws/',
    
  
  },
  plantApis:{
    'AccessControlUpdate':'https://b22nebuootkp356bvad6enemya0anyop.lambda-url.us-east-2.on.aws/'
  },
  usersApis:{
      'changePassword':'https://czwqtiwck52msmr2oo6e66koxa0ikurm.lambda-url.us-east-2.on.aws/',
    }
};