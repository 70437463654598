import { notification } from 'antd';
import { list, create, update, remove } from '@/services/firestore';

  const namespace = 'productLog';
  export default {
    namespace,
  
    state: {
      loadingList: false,
      loadingUpdate: false,
      loadingCreate: false,
      loadingRemove: false,
  
      list: [],
      current: null
    },
  
    effects: {
    //   *list({ payload }, { call, put, takeEvery, take }) {
    //     // clear old before new request
    //     yield put({
    //       type: `clear:${namespace}/list`,
    //     });
  
    //     yield put({
    //       type: 'save',
    //       payload: { loadingList: true },
    //     });

    //     // create subscription
    //     const service = yield call(list, {
    //       module: namespace,
    //       orderBy: ['createdAt', 'desc'],
    //       ...payload,
    //     });
  
    //     function* push(response) {
    //       yield put({
    //         type: 'saveList',
    //         payload: Array.isArray(response) ? response : [],
    //       });
    //     }
  
    //     // on every callback from service
    //     yield takeEvery(service, push);
  
    //     // unsubscribe & clear when this action fired
    //     yield take(`clear:${namespace}/list`);
    //     service.close();
    //     yield put({
    //       type: 'clearList',
    //     });
    //   },
  
      *create({ payload }, { call, put, select }) {
        yield put({
          type: 'save',
          payload: { loadingCreate: true },
        });
  
        try {
          const machine = yield select(state => state.productionLine.machine);
          payload.params.machine = machine ? machine.machineName : null
          const response = yield call(create, {
            module: namespace,
            ...payload,
          });
          if (response) {
            notification.success({
              message: 'Product Changeover Recorded',
            });

            yield put({
              type: 'save',
              payload: { current: response },
            });
          }
          yield put({
            type: 'save',
            payload: { loadingCreate: false },
          });
        } catch (error) {
          // console.log("error", error);
          notification.error({
            message: error.message,
          });
  
          yield put({
            type: 'save',
            payload: { loadingCreate: false },
          });
        }
      },
  
    //   *update({ payload }, { call, put }) {
    //     try {
    //       yield put({
    //         type: 'save',
    //         payload: { loadingUpdate: true },
    //       });
    //       const response = yield call(update, {
    //         module: namespace,
    //         ...payload,
    //       });
    //       yield put({
    //         type: 'save',
    //         payload: { loadingUpdate: false },
    //       });
    //     } catch (error) {
    //       console.error('update error', error);
    //       notification.error({
    //         message: error.message,
    //       });
    //       yield put({
    //         type: 'save',
    //         payload: { loadingUpdate: false },
    //       });
    //     }
    //   },
  
    //   *remove({ payload }, { call, put }) {
    //     yield put({
    //       type: 'save',
    //       payload: { loadingRemove: true },
    //     });
  
    //     try {
    //       const response = yield call(remove, {
    //         module: namespace,
    //         ...payload,
    //       });
    //       if (response) {
    //         notification.success({
    //           message: 'Product deleted',
    //         });
    //       }
    //       yield put({
    //         type: 'save',
    //         payload: { loadingRemove: false },
    //       });
    //     } catch (error) {
    //       // console.log("error", error);
    //       notification.error({
    //         message: error.message,
    //       });
  
    //       yield put({
    //         type: 'save',
    //         payload: { loadingRemove: false },
    //       });
    //     }
    //   },
      *unsubscribe(_, { put }) {
        yield put({ type: `clear:${namespace}/list` });
      },
    },
  
    reducers: {
      save(state, { payload }) {
        return {
          ...state,
          ...payload,
        };
      },
      saveList(state, { payload }) {
        return {
          ...state,
          list: payload,
          loadingList: false,
        };
      },
      clearList(state) {
        return {
          ...state,
          list: [],
          loadingList: false,
        };
      },
    },
  };