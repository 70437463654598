export default {
  'RBAC.capabilities.view-all': 'View All Pages',
  'RBAC.capabilities.view-home': 'View Home Page',
  'RBAC.capabilities.view-dashboard': 'View Dashboard Page',
  'RBAC.capabilities.view-observer': 'View Line Operator Page',
  'RBAC.capabilities.view-downtimeLog': 'View Downtime Log Page',
  'RBAC.capabilities.view-line-settings': 'View Line Settings Page',
  'RBAC.capabilities.view-plant-settings': 'View Plant Settings Page',
  'RBAC.capabilities.end-shift': 'View Force Shift Stop',
  'RBAC.capabilities.product-write-access': 'View Product Changeover Button',
  'RBAC.capabilities.edit-line-settings': 'Update Line Settings',
  'RBAC.capabilities.edit-role': 'View Roles',
  'RBAC.capabilities.update-downtimeLog': 'Update Downtime Logs',
  'RBAC.capabilities.audit-trail-access': 'View Audit Trails Page',
  'RBAC.capabilities.machine-access': 'View Machines/Stations',
  'RBAC.capabilities.create-plant': 'Create Plant',
  'RBAC.capabilities.delete-plant': 'Delete Plant',
  'RBAC.capabilities.create-productionLine': 'Create Production Line',
  'RBAC.capabilities.delete-productionLine': 'Delete Production Line',
  'RBAC.capabilities.create-dynamicAdjustments-rework': 'View Rework Button',
  'RBAC.capabilities.create-dynamicAdjustments-add-rejections': 'View Rejections Button',
  'RBAC.capabilities.view-integration-details': 'View Integration Details',
  'RBAC.capabilities.view-leaderboard': 'View Line Board Page',
  'RBAC.capabilities.crfs-enabled': 'View Plant Averages',
  'RBAC.capabilities.crfs-hilal-maintenance': 'View Maintainance KPI',
  'RBAC.capabilities.crfs-hilal-merged': 'View Merged Dashboard',
  'RBAC.capabilities.line-performance-monitor':'View Line Performance Monitor Page',
  'RBAC.capabilities.line-level-board':'View Line Level Board',
  'RBAC.capabilities.manual-entry': 'View Manual Entry',
  'RBAC.capabilities.change-date-filters-operator-page':'Date Change - Line Operator Page',
  'RBAC.capabilities.change-date-filters-line-performance':'LPM Date Change Access',
  'RBAC.capabilities.change-date-filters-line-level-board': 'LLB Date Change Access',
  'RBAC.capabilities.force-shift-stop-write':'Force Shift Stop Access',
  'RBAC.capabilities.support-button-write':'Support Button Access',
  'RBAC.capabilities.reports-access':'Reports Extract Access',
  'RBAC.capabilities.product-change-line-operator':'Product Changeover - Line Operator Access',
  'RBAC.capabilities.rework-access':'Add Reworks Manually',
  'RBAC.capabilities.add-rejections-access':'Add Rejections Manually',
  'RBAC.capabilities.merge-downtime-access':'Merge Downtimes Access',
  'RBAC.capabilities.demerge-downtime-access':'Demerge Downtime Access',
  'RBAC.capabilities.mark-downtime-access':'Downtime Reason Marking Access',
  'RBAC.capabilities.change-date-filters-downtime-log':'Downtime Log Date Filter Access',
  'RBAC.capabilities.edit-downtime-access':'Edit Downtime Access',
  'RBAC.capabilities.line-information-access':'Update Line Information Access',
  'RBAC.capabilities.shift-access':'Update Shift Changes Access',
  'RBAC.capabilities.planned-downtime-access':'Update Planned Downtime Access',
  'RBAC.capabilities.product-access':'Product Changes Access',
  'RBAC.capabilities.weekly-access':'Weekly Schedule Changes Access',
  'RBAC.capabilities.downtime-setting-access':'Update Downtime Settings Access',
  'RBAC.capabilities.daily-target-access':'Update Daily Targets Access',
  'RBAC.capabilities.manual-entry-access':'Update Manual Entry',
  'RBAC.capabilities.plant-user-access':'User Detail Changes Access',
  'RBAC.capabilities.plant-role-access':'Update Role Changes Access',
  'RBAC.capabilities.plant-alerts-access':'Update Alerts Changes Access',
  'RBAC.capabilities.site-champion-access':'Update Site Champion Access',
  'RBAC.capabilities.generate-audit-report':'Audit Report Access',
  'RBAC.capabilities.help-support-access':'Support/Ticket Access',
  'RBAC.capabilities.user-export-report':'User Export Report',
  'RBAC.capabilities.weekly-target-access':'Weekly Target Changes Access',
  'RBAC.capabilities.weekly-target-page':'View Weekly Target Page',
  'RBAC.capabilities.force-stop-page':'View Force Stop Page',
  'RBAC.capabilities.view-batchwise-page':'View Batch Dashboard',
  'RBAC.capabilities.start-stop-production':'Start/Pause Production'


  //'RBAC.capabilities.reports-access':'Reports Access',
  //'RBAC.capabilities.reports-access':'Reports Access',
  //'RBAC.capabilities.reports-access':'Reports Access',
  //'RBAC.capabilities.force-shift-stop-write':'Force Shift Stop Write Operation',
};
